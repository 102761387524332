import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, debounceTime, distinctUntilChanged, forkJoin, map, Observable, switchMapTo, takeUntil } from 'rxjs';
import { data } from 'src/app/services/mock-data';
import { CompetitorRestaurantService } from '../services/competitor-restaurant.service';
import { CompetitorService } from '../services/competitor.service';
import { MenuItemPriceService } from '../services/menu-item-price.service';
import { RestaurantService } from '../services/restaurant.service';
import { environment } from 'src/environments/environment';
import { MenuCategoryService } from '../services/menu-category.service';
import IdTokenVerifier from 'idtoken-verifier';
import { ToastService } from '../services/toast-service';
import { LocalStoreService } from '../services/local-store.service';
import * as dayjs from 'dayjs';
import { observe } from 'rxjs-observe';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Form, FormControl } from '@angular/forms';
import { SharedEventService } from '../services/shared-event.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SaveModelModellingtoolComponent } from '../components/save-model-modellingtool/save-model-modellingtool.component';
import * as XLSX from 'xlsx';
import { ConceptPluSalesService } from '../services/concept-plu-sales.service';
import { TierService } from '../services/tier.service';
import { ModelingToolPricingTableComponent} from '../modeling-tool-pricing-table/modeling-tool-pricing-table.component'


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  searchText = '';
  groupSearchText = '';
  activeId = 1;
  competitors: any[] = [];
  SourceCompetitors: any[] = [];
  sideLinks: any[] = [];
  pricingTable: any;
  currentPricingTable: any[] = [];
  restrurantNum: any[] = [];
  selectedRestaurantNumber: any;
  selectedRestaurantid: any;
  selectedRestaurantGroup: any;
  competitorsForModelingTool: object[] = [];
  checkedCompetitors: boolean = true;
  checkedModelCompetitors: boolean = true;
  checkedMCD: boolean = true;
  checkedWendy: boolean = true;
  checkedOthers: boolean = true;
  currentRestaurant: any;
  competitorViewRestaurant: any;
  nearMcdCompetitors: any[] = [];
  nearWendyCompetitors: any[] = [];
  nearOtherCompetitors: any[] = [];
  selectedMCD: object[] = [];
  selectedWendy: object[] = [];
  selectedOthers: object[] = [];
  selectedMCDL: object[] = [];
  selectedWendyL: object[] = [];
  selectedOthersL: object[] = [];
  selectedCompetitors: object[] = [];
  nearestCompetitors: object[] = [];
  othersLabel: string = '';
  availableRestaurant: any[] = [];
  changeRestaurant: any[] = [];
  availableRestaurantGroup: any[] = [];
  defaultSelectedCategoryId = 'a1b858cb-cda3-4bdd-9f6e-96a125efbc31';
  defaultSelectedConceptId = 'e12914f1-6d0e-4a51-9892-675fa935af58';
  selectedCategoryId = '';
  selectedSubGroup;
  selectedConceptId = '';
  pricingInput = {};
  pricingIsLoading: boolean = false;
  windowRef: Window;
  userName: string = '';
  allCompetitors: any = [];
  assetUrl: string;
  selectedBentchMark = [];
  benchMarkToShow;
  benchMarkToHide;
  restaurantSelected;
  selectedServiceMode: string = '11e6cd44-4b53-4579-bd23-8f719677470a';
  benchMarkIsLoading = false;
  currentConceptRestaurant;
  currentCompRestaurant;
  currentUser;
  scrollY: number = 0;
  showSavedModel = false;
  savedModel;
  editMode: boolean[] = [];
  disableMode: boolean[] = [];
  index: number;
  bkCompId: string;
  pageIsLoading = true;
  isManageModelLoading = true;
  TierService: TierService;
  availableBenchMark = [
    // { name: 'Sales Band', isShow: true },
    { name: 'National', isShow: true, benchMarkCardName: "National" },
    { name: 'BKC Division', isShow: true, benchMarkCardName: "BkcDivision" }
  ]
  links = [
    { title: 'Market Intelligence', fragment: 'market-intelligence', id: 'intelligence' },
    //  { title: 'Modeling Tool', fragment: 'modeling-tool', id: 'modeling' }
  ];
  selectedCategory: string = 'anchor';
  isLoaded = false;
  filters = { restaurant_group: '', restaurant_num: '', competitors: [], benchMarks: [], menuCategory: '' };
  definedFilters;
  filtersProxy;
  toggleFilterSaved = false;
  isFirstLoad = true;
  modelDeleteIds: any[] = [];
  userId;
  dateRange = [];
  selectedDateRange: any;
  selectedModelingRes: any;
  restaurantGroupsForModelChanges: any[] = [];
  applyModelName = '';
  availableModelingRestaurant: any[] = [];
  availableModelingRestaurantGroup: any[] = [];
  selectedModelingNums: any[] = [];
  modelIsApplied = false;
  showModelingTab: boolean = environment.showModelingTab;
  showImpactTab: boolean = environment.showImpactTab;
  showTierTab : boolean = environment.showTierTab;
  showMenuTab: boolean = environment.showMenuTab;
  canChange: boolean = true;
  downloadXlsxBtn = false;
  modalPriceToSave: any = [];
  resChangedInBenchView: boolean = false;
  availableSoldTypes = [];
  availableServiceMode = [];
  salesCardDetails: any[] = [];
  isServiceLoaded = false;
  showCard;
  showApplyMultiModel = false;
  modelingDateRange = [];
  selectedModellingDateRange
  hasCompetitor;
  categoryList=[];
  modelVersionName:string = null;
  downloadDisabled = false;
  @ViewChild(NgbNav) nav: NgbNav;
  @ViewChild('notSavedModel') mymodal: ElementRef;
  @ViewChild(SaveModelModellingtoolComponent) saveModalRef: SaveModelModellingtoolComponent;
  @ViewChild('manageModal') manageModal:ElementRef;
  @ViewChild('exportPrice') exportPrice:ElementRef;
  @ViewChild('modelingPricingTable') modelingPricingTable!: ModelingToolPricingTableComponent;

  constructor(private ngbmodalService: NgbModal, private modalService: NgbOffcanvas, public route: ActivatedRoute, private competitorService: CompetitorService, private sanitizer: DomSanitizer, private localStore: LocalStoreService, private toastService: ToastService, private menuCategoryService: MenuCategoryService, private competitorResService: CompetitorRestaurantService, private menuItemPriceService: MenuItemPriceService, private reataurantService: RestaurantService, @Inject('Window') window: Window, private sharedService: SharedEventService, private salesService: ConceptPluSalesService, TierService: TierService) {
    this.windowRef = window;
    this.TierService = TierService;
    this.sharedService.canChange.subscribe(res => {
      this.canChange = res;
    })
    this.sharedService.categoryList.subscribe(res=>{
      this.categoryList = res;
    })
    this.sharedService.openManageModelModalEvent.subscribe(ev => {
      this.openManageModel(this.manageModal);

    });
    

    this.sharedService.openExportModalEvent.subscribe(ev => {
        this.openPriceExport(this.exportPrice);
      });

  }

  async ngOnInit(): Promise<void> {
    await this.delay(3000)
    this.isLoaded = true;
    this.userId = this.getUserId();
    this.definedFilters = this.localStore.getData("filters");
    if (this.definedFilters) {
      this.definedFilters = JSON.parse(this.definedFilters);
    }
    this.menuItemPriceService.getAllServiceMode().subscribe(res => {
      res = res.sort((a, b) => {
        return a.sortId - b.sortId;
      });
      this.isServiceLoaded = true;
      this.availableServiceMode = res;
      this.selectedServiceMode = this.availableServiceMode[0].id;
    })
    this.menuItemPriceService.getAllSoldTypes().subscribe(res => {
      res.forEach(el => {
        el.name == 'A La Carte' ? el.isSelected = true : el.isSelected = false;
        this.availableSoldTypes.push(el);
      })
      this.availableSoldTypes.sort((a, b) => {
        return a.sortId - b.sortId;
      })
    })
    this.menuItemPriceService.getTimePeriods().subscribe(res => {
      res = res.sort((a, b) => {
        return a.num - b.num;
      });
      const eles = res.filter(ele => ele.showInSelector);
      this.dateRange = eles;
      this.modelingDateRange = eles;
      this.selectedModellingDateRange = this.modelingDateRange[1]?.name;
      this.selectedDateRange = this.dateRange[1]?.name;

    });
    const { observables, proxy } = observe(this.filters);
    this.filtersProxy = proxy;
    this.competitorService.getAll().subscribe(res => {
      this.allCompetitors = res;
      this.bkCompId = this.allCompetitors.filter(x => x.name == 'Burger King')[0].id;
    })

    combineLatest([observables.restaurant_group, observables.restaurant_num, observables.competitors, observables.benchMarks, observables.menuCategory])
      .pipe(debounceTime(5000), distinctUntilChanged())
      .subscribe(data => {
        if (!this.isFirstLoad) {
          const today = dayjs().format('MM-DD-YYYY') + ' at ' + dayjs().format('HH:mm A');
          this.toastService.show("Auto save is on! Last saved " + today, { className: "", delay: 3000 });
          data['userId'] = this.userId;
          this.localStore.saveFilterState(data);
        } else {
          this.isFirstLoad = false;
        }

      });
    if (this.definedFilters?.benchMarks) {
      for (var i = 0; i < this.definedFilters.benchMarks.length; i++) {
        const mark = this.definedFilters.benchMarks[i];
        this.availableBenchMark[i]['isShow'] = mark.toShow;
      }
    }
    this.assetUrl = environment.assetUrl;
    if (this.activeId == 2) {
      if (this.isFirstLoad && this.definedFilters && this.definedFilters?.menuCategory && this.userId == this.definedFilters.userId) {
        this.selectedCategoryId = this.definedFilters.menuCategory
        this.selectedConceptId = this.defaultSelectedConceptId;
      } else {
        this.selectedCategoryId = this.defaultSelectedCategoryId;
        this.selectedConceptId = this.defaultSelectedConceptId;
      }
    }
    if (this.activeId == 1) {
      if (this.isFirstLoad && this.definedFilters && this.definedFilters?.menuCategory && this.userId == this.definedFilters.userId) {
        this.selectedSubGroup = this.definedFilters.menuCategory
        this.selectedConceptId = this.defaultSelectedConceptId;
      } else {
        this.selectedConceptId = this.defaultSelectedConceptId;
      }
    }
    this.othersLabel = 'Jack In A Box';
    Object.keys(data.competitors).forEach((k, i) => {
      if (k === this.restrurantNum[0]) {
        var d = Object.values(data.competitors)[i];
        this.competitors = d;
        return;
      }
    })
    this.competitors.sort((a, b) => {
      return (a.isUserRestaurant === b.isUserRestaurant) ? 0 : a.isUserRestaurant ? -1 : 1;
    });

    this.pageIsLoading = false;
    this.SourceCompetitors = this.competitors;
    if (this.activeId == 2) {
      this.menuCategoryService.getAll().subscribe(
        {
          next: (data) => {
            this.sideLinks = data as [];
            this.sideLinks.sort((a, b) => {
              return a.sortId - b.sortId;
            });
            this.selectedCategory = (this.sideLinks.find((ele) => {
              return ele.id == this.selectedCategoryId
            })).name;
          }
        }
      );
    }
    this.reataurantService.getRestaurantsForUser().subscribe(res => {
      if (!res || (res as [])?.length == 0) {
        alert('Sorry, RMP is not configured for this account yet. Please contact the system administator.');
        this.isLoaded = true;
        this.pageIsLoading = false;
        this.pricingIsLoading = false;
        this.isManageModelLoading = false;
        this.benchMarkIsLoading = false;
        return;
      }
      let response: object[] = [];
      this.currentUser = res[0].userId;
      Object.values(res).forEach((i) => {
        // const checkIdx = response.findIndex(ele => {
        //   return ele['restaurantNum'] === i.restaurantNum;
        // })
        // if (checkIdx < 0) {
        response.push(i);
        // }
      })
      response = response.sort((a: any, b: any) => {
        return a.restaurantNum - b.restaurantNum;
      })
      this.changeRestaurant = response;

      if (this.changeRestaurant?.length > 0) {
        var options = this.changeRestaurant.filter((item, i, arr) => arr.findIndex((t) => t.groupId === item.groupId) === i);
        this.availableRestaurantGroup = options;
        this.availableRestaurantGroup = this.availableRestaurantGroup.sort((a, b) => {
          let textA = a.groupName.toUpperCase();
          let textB = b.groupName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
        if (this.isFirstLoad && this.definedFilters && this.definedFilters?.restaurant_group && this.userId == this.definedFilters.userId) {
          this.selectedRestaurantGroup = this.definedFilters?.restaurant_group;
          this.selectedRestaurantNumber = this.definedFilters?.restaurant_num;
        } else {
          this.selectedRestaurantGroup = options[0].groupName;
        }
      }

      response.forEach((x: any) => {
        if (x.groupName == this.availableRestaurantGroup[0].groupName) {
          this.availableRestaurant.push(x);
        }
      })

      this.availableRestaurant = this.getUniqueRestaurantSet(this.availableRestaurant);
      if (this.availableRestaurant?.length > 0) {
        this.selectedRestaurantNumber = (this.availableRestaurant[0].restaurantNum);
        this.selectedRestaurantid = this.availableRestaurant[0].id;
      }

      if (this.isFirstLoad && this.definedFilters?.restaurant_group && this.userId == this.definedFilters.userId) {
        this.refreshRestaurantNum(this.definedFilters?.restaurant_group);
      } else if (this.selectedRestaurantNumber) {
        this.RefreshCompetitors(this.selectedRestaurantNumber);
        this.setUserName();
      }
    })

  }
  refreshRestaurantNum(groupName) {
    this.prevRestGrp = groupName;
    this.searchText = "";
    this.groupSearchText = "";
    this.availableRestaurant = [];
    this.competitorsForModelingTool = []
    this.changeRestaurant.forEach((x: any) => {
      if (x.groupName == groupName) {
        this.availableRestaurant.push(x);
      }
    });
    this.availableRestaurant = this.getUniqueRestaurantSet(this.availableRestaurant);

    if (this.isFirstLoad && this.definedFilters?.restaurant_num) {
      this.selectedRestaurantNumber = this.definedFilters?.restaurant_num;
    } else if (this.availableRestaurant?.length > 0) {
      this.selectedRestaurantNumber = (this.availableRestaurant[0].restaurantNum);
      this.selectedRestaurantid = this.availableRestaurant[0].id;
    }

    if (this.selectedRestaurantNumber) {
      this.RefreshCompetitors(this.selectedRestaurantNumber);
    }
  }


  async RefreshCompetitors(val?: string) {

    this.selectedModelingRes = val;
    if (!this.checkedCompetitors) {
      this.resChangedInBenchView = true;
    }
    this.groupSearchText = ""
    this.searchText = "";
    this.competitorsForModelingTool = []
    this.selectedRestaurantid = this.availableRestaurant.filter(el => {
      return el.restaurantNum == val;
    })[0];
    this.restaurantSelected = val;

    this.currentPricingTable = this.pricingTable;
    this.nearMcdCompetitors = [];
    this.nearWendyCompetitors = [];
    this.nearOtherCompetitors = [];
    this.selectedCompetitors = [];
    this.selectedMCD = [];
    this.selectedWendy = [];
    this.selectedOthers = [];
    this.filtersProxy.restaurant_group = this.selectedRestaurantGroup;
    this.filtersProxy.restaurant_num = this.selectedRestaurantNumber;
    if (!this.checkedCompetitors) {
      this.getBentchMark()
    } else {
      let bkId = this.bkCompId;
      this.reataurantService.getCurrentRestaurantByNum(this.selectedRestaurantNumber).subscribe(resp => {
        this.competitorViewRestaurant = resp[0];
        this.competitorResService.getCompetitors(val, bkId).subscribe(res => {
          this.currentRestaurant = res[0];
          this.currentRestaurant.addr1 = this.competitorViewRestaurant?.addr1;
          this.currentRestaurant.cityName = this.competitorViewRestaurant?.cityName;
          this.currentRestaurant.stateCd = this.competitorViewRestaurant?.stateCd;
          this.currentRestaurant.zipCd = this.competitorViewRestaurant?.zipCd;
          this.currentRestaurant.medianHhIncome = this.competitorViewRestaurant?.medianHhIncome;
          this.currentCompRestaurant = res[0];
          this.currentCompRestaurant.addr1 = this.competitorViewRestaurant?.addr1;
          this.currentCompRestaurant.cityName = this.competitorViewRestaurant?.cityName;
          this.currentCompRestaurant.stateCd = this.competitorViewRestaurant?.stateCd;
          this.currentCompRestaurant.zipCd = this.competitorViewRestaurant?.zipCd;
          this.currentCompRestaurant.medianHhIncome = this.competitorViewRestaurant?.medianHhIncome;
  
          let mcDId = this.allCompetitors.filter(x => x.name == 'McDonalds')[0]?.id;
          let wendyId = this.allCompetitors.filter(x => x.name == 'Wendys')[0]?.id;
          let competitors = [];
  
          forkJoin(this.competitorResService.getCompetitorsyBrand(val, mcDId), this.competitorResService.getCompetitorsyBrand(val, wendyId), this.competitorResService.getCompetitorsOthers(val)).pipe(map(x => {
            Object.values(x[0]).forEach((i, index) => {
              if (index < 5) {
                competitors.push(i);
                this.nearMcdCompetitors.push(i);
              }
            });
            Object.values(x[1]).forEach((i, index) => {
              if (index < 5) {
                competitors.push(i);
                this.nearWendyCompetitors.push(i);
              }
            });
            Object.values(x[2]).forEach((i, index) => {
              competitors.push(i);
              this.nearOtherCompetitors.push(i);
            });
            this.hasCompetitor = competitors && competitors.length > 0;
            let groupedOtherCompetitors = this.groupBy(this.nearOtherCompetitors, 'competitorName');
            let values = [];
            for (var key in groupedOtherCompetitors) {
              values.push(groupedOtherCompetitors[key]);
            }
            this.nearOtherCompetitors = values;
  
            return x.flat()
          })
          ).subscribe(result => {
            let val: object[] = [];
            Object.values(result).forEach(element => {
              val.push(element)
            });
            if (environment?.isTestEnvironment) {
              for (var i = 0; i < val?.length; i++) {
                const regex = new RegExp("[a-zA-Z0-9.]", "g");
                val[i]['addr1'] = val[i]['addr1'].replace(regex, '*');
                val[i]['cityName'] = val[i]['cityName'].replace(regex, '*');
                val[i]['stateCd'] = val[i]['stateCd'].replace(regex, '*');
                val[i]['zipCd'] = val[i]['zipCd'].replace(regex, '*');
              }
            }
  
            val.sort((a: any, b: any) => {
              return a.distance - b.distance;
            })
  
            this.competitors = val.slice(0, 4)
            val.slice(0, 4).forEach((el: any) => {
              if (el.competitorName == 'Wendys') {
                this.selectedWendy.push(el)
                this.selectedCompetitors.push(el)
              }
              else if (el.competitorName == 'McDonalds') {
                this.selectedMCD.push(el)
                this.selectedCompetitors.push(el)
              }
              else {
                this.selectedOthers.push(el)
                this.selectedCompetitors.push(el)
              }
  
              this.nearestCompetitors = JSON.parse(JSON.stringify(this.selectedCompetitors));
            })
            this.competitors.unshift(this.currentRestaurant);
            //Code below is for tracking auto save features
            const selectedCmps = [];
            selectedCmps.push(...this.selectedMCD);
            selectedCmps.push(...this.selectedWendy);
            selectedCmps.push(...this.selectedOthers);
            const cmps = [];
            selectedCmps.forEach(cmp => {
              cmps.push({ id: cmp.id, competitor_id: cmp.competitorId, num: cmp.num });
            });
            const allNearCmps = [];
            allNearCmps.push(...this.nearMcdCompetitors);
            allNearCmps.push(...this.nearWendyCompetitors);
            allNearCmps.push(...this.nearOtherCompetitors);
            this.filters.competitors = cmps;
            this.filtersProxy.competitors = cmps;
            if (this.definedFilters?.competitors && this.definedFilters.restaurant_num == this.selectedRestaurantNumber && this.userId == this.definedFilters.userId) {
              this.selectedMCD = [];
              this.selectedWendy = [];
              this.selectedOthers = [];
              this.selectedCompetitors = [];
              for (var i = 0; i < this.definedFilters?.competitors.length; i++) {
                const cmp = this.definedFilters?.competitors[i];
                let foundCmp = allNearCmps.find(e => e['competitorId'] == cmp.competitor_id && e['num'] == cmp.num);
                if (!foundCmp) {
                  const otherCmps = allNearCmps.filter(e => e instanceof Array);
                  const flatOtherCmps = otherCmps.flat();
                  foundCmp = flatOtherCmps.find(e => e['competitorId'] == cmp.competitor_id && e['num'] == cmp.num);
                }
  
                switch (foundCmp['competitorName']) {
                  case 'McDonalds':
                    this.selectedMCD.push(foundCmp);
                    this.selectedCompetitors.push(foundCmp);
                    break;
                  case 'Wendys':
                    this.selectedWendy.push(foundCmp);
                    this.selectedCompetitors.push(foundCmp);
                    break;
                  default:
                    this.selectedOthers.push(foundCmp);
                    this.selectedCompetitors.push(foundCmp);
                    break;
                }
  
              }
              this.competitors = [];
              if (this.currentRestaurant !== undefined) {
                this.competitors.push(this.currentRestaurant);
              } else {
                this.competitors.push({}); // push empty because of potential race condition so page data still loads
              }
              this.competitors.push(...this.selectedMCD);
              this.competitors.push(...this.selectedWendy);
              this.competitors.push(...this.selectedOthers);
            }
            this.competitors.sort((a: any, b: any) => {
              return a.distance - b.distance;
            });
            this.pricingInput = { selectedRestaurantNumber: this.selectedRestaurantNumber, competitors: this.competitors };
            this.selectedWendyL = this.selectedWendy;
            this.selectedMCDL = this.selectedMCD;
            this.selectedOthersL = this.selectedOthers;
          });
        });
      });
    }

  }

  getCategoryPricing(catId: string, cateName: string) {
    this.selectedCategoryId = catId;
    this.selectedCategory = cateName;
    this.filtersProxy.menuCategory = catId;
  }

  onToggleWendy(event: MatSlideToggleChange) {
    this.checkedWendy = event.checked;
    this.RefreshFilterCompetitors();
  }

  onToggleMCD(event: MatSlideToggleChange) {
    this.checkedMCD = event.checked;
    this.RefreshFilterCompetitors();
  }
  onToggleOthers(event: MatSlideToggleChange) {
    this.checkedOthers = event.checked;
    this.RefreshFilterCompetitors();
  }
  onToggleCompetitors(event: MatSlideToggleChange) {
    this.checkedCompetitors = event.checked;
    if (!this.checkedCompetitors) {
      this.getBentchMark();
    }
    if (event.checked && this.resChangedInBenchView) {
      this.RefreshCompetitors(this.selectedRestaurantNumber);
      this.resChangedInBenchView = false;
    }
  }

  RefreshFilterCompetitors() {
    let refreshedCompetitors: any[] = [];
    this.selectedCompetitors.sort((a: any, b: any) => {
      return a.distance - b.distance;
    })
    refreshedCompetitors.push(this.currentRestaurant);
    refreshedCompetitors = refreshedCompetitors.concat(this.selectedCompetitors);
    this.SourceCompetitors?.forEach((element: any) => {
      if (element.brand == "WDY") {
        if (this.checkedWendy)
          refreshedCompetitors.push(element);
      }
      else if (element.brand == "MCD") {
        if (this.checkedMCD)
          refreshedCompetitors.push(element);
      }
      else if (element.brand == "JKB") {
        if (this.checkedOthers)
          refreshedCompetitors.push(element);
      }
      else {
        refreshedCompetitors.push(element);
      }
    });
    this.competitors = refreshedCompetitors;

    let refreshedPricing: any[] = [];
    this.currentPricingTable?.forEach((row: any, i) => {
      const dataRow = row.filter((element: any) => {
        if (element.brand == "WDY") {
          if (this.checkedWendy)
            return element;
        }
        else if (element.brand == "MCD") {
          if (this.checkedMCD)
            return element;
        }
        else if (element.brand == "JKB") {
          if (this.checkedOthers)
            return element;
        }
        else {
          return element;
        }
      });
      refreshedPricing[i] = dataRow;
    });

    this.pricingTable = refreshedPricing;
  }
  changeCompititors() {
    this.selectedCompetitors = [];
    this.competitorsForModelingTool = []
    this.selectedMCD.forEach(mcd => {
      this.selectedCompetitors.push(mcd);
    })
    this.selectedWendy.forEach(wendy => {
      this.selectedCompetitors.push(wendy);
    })
    this.selectedOthers.forEach(other => {
      this.selectedCompetitors.push(other);
    })

    this.selectedWendyL = this.selectedWendy;
    this.selectedMCDL = this.selectedMCD;
    this.selectedOthersL = this.selectedOthers;

    if (this.selectedCompetitors.length <= 4) {
      this.RefreshFilterCompetitors();
    }
    this.pricingInput = { selectedRestaurantNumber: this.selectedRestaurantNumber, competitors: this.competitors }
    const selectedCmps = [];
    selectedCmps.push(...this.selectedMCD);
    selectedCmps.push(...this.selectedWendy);
    selectedCmps.push(...this.selectedOthers);
    const cmps = [];
    selectedCmps.forEach(cmp => {
      cmps.push({ id: cmp.id, competitor_id: cmp.competitorId, num: cmp.num });
    });
    this.filters.competitors = cmps;
    this.filtersProxy.competitors = cmps;
  }

  check(data: object) {
    if (this.selectedCompetitors.includes(data)) {
      return false;
    } else {
      return true;
    }
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  stringContains(num: number) {
    const str = num + '';
    if (str.indexOf(this.searchText) > 0) {
      return true;
    }
    return false;
  }
  getGroupSearchText(text: string) {
    if (this.groupSearchText?.length == 0) {
      return text;
    } else {
      const str = text + '';
      var regex = new RegExp(this.groupSearchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  }
  getSearchText(num: string) {
    num = num.replace(/\,/g, '');
    if (this.searchText?.length == 0) {
      return num;
    } else {
      const str = num + '';
      var regex = new RegExp(this.searchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  }
  openManage(context: TemplateRef<any>) {
    this.modalService.open(context, { position: 'end', backdrop: true, keyboard: false });
  }
  setUserName() {
    const token = (this.windowRef as any).userTK;
    var verifier = new IdTokenVerifier({ issuer: 'RSI', audience: 'RMP' });
    var tokenPayload = verifier.decode(token);
    this.userName = (tokenPayload.payload as any).firstName + ' ' + (tokenPayload.payload as any).lastName;
  }
  getEmail() {
    const token = (this.windowRef as any).userTK;
    var verifier = new IdTokenVerifier({ issuer: 'RSI', audience: 'RMP' });
    var tokenPayload = verifier.decode(token);
    return (tokenPayload.payload as any).email;
  }
  getUserId() {
    const token = (this.windowRef as any).userTK;
    var verifier = new IdTokenVerifier({ issuer: 'RSI', audience: 'RMP' });
    var tokenPayload = verifier.decode(token);
    return (tokenPayload.payload as any).userId;
  }
  renameModel(i) {
    this.editMode[i] = true;
  }

  editModel(name){
    this.modelVersionName = name;

  }
  deleteModel(item: number, name) {
    this.modelDeleteIds.push(name);
    this.disableMode[item] = true;
    this.editMode[item] = false;
  }
  confirmChanges(){
      this.reataurantService.deleteModels(this.modelDeleteIds).subscribe(
        {
          next: () => {
            console.log("Models deleted successfully");
            this.editMode = new Array(this.savedModel.length).fill(false);
            this.disableMode = new Array(this.savedModel.length).fill(false);
            this.modelDeleteIds = [];
          }, 
          error: () => {
            console.log("Error deleting models");
          }
        }
    
      );
      this.savedModel.forEach(model => {

        this.reataurantService.updateModelName(model.oldName, model.name).subscribe(
          {
            next: () => {
              console.log("Models updated successfully");
              this.editMode = new Array(this.savedModel.length).fill(false);
              this.disableMode = new Array(this.savedModel.length).fill(false);
            }, 
            error: () => {
              console.log("Error updating models");
            }
          }
        );
      })

  }
  ChangeBenchMarkSelection() {
    this.availableBenchMark = [...this.availableBenchMark];
    this.selectedBentchMark[1].toShow = this.availableBenchMark[0].isShow;
    this.selectedBentchMark[2].toShow = this.availableBenchMark[1].isShow;
    // this.selectedBentchMark[3].toShow = this.availableBenchMark[2].isShow;

    const benchMarkState = [];
    this.selectedBentchMark.forEach(el => {
      if (el.title) {
        benchMarkState.push({ name: el.title, toShow: el.toShow });
      }
    })
    this.filtersProxy.benchMarks = benchMarkState;
    this.filterBentchMark();
  }
  filterBentchMark() {
    if (this.selectedBentchMark[0].dmaShow == false) {
      this.selectedBentchMark = this.selectedBentchMark.filter(el => el.title != 'DMA')
    }

    this.benchMarkToShow = this.selectedBentchMark.filter(el => el.toShow == true);
    this.benchMarkToHide = this.selectedBentchMark.filter(el => el.toShow == false)
  }

  getBentchMark() {
    this.reataurantService.getCurrentRestaurantByNum(this.restaurantSelected).subscribe(res => {
      this.currentConceptRestaurant = res[0];
      const newLocal = this;
      newLocal.selectedBentchMark = [];

      this.selectedBentchMark.push(this.currentConceptRestaurant);
      this.selectedBentchMark[0].toShow = true;
      this.benchMarkIsLoading = true;
      forkJoin([
        //   this.reataurantService.GetRestaurantSalesBandBenchMark(this.currentConceptRestaurant.num),
        this.reataurantService.GetRestaurantNationalBenchMark(this.currentConceptRestaurant.num),
        this.reataurantService.GetRestaurantBkcDivisionBenchMark(this.currentConceptRestaurant.num)]).subscribe((res: any) => {

          // res[0].title = 'Sales Band';
          // res[0].showName = res[0].salesbandName;
          // res[0].toShow = this.availableBenchMark[0].isShow;
          res[0].title = 'National';
          res[0].toShow = this.availableBenchMark[0].isShow;
          res[1].title = 'BKC Division';
          res[1].toShow = this.availableBenchMark[1].isShow;
          res[1].bkcDivisionName = res[1]?.bkcDivisionName != null ? res[1]?.bkcDivisionName.split(' ')[0] : '';

          res.forEach(el => {
            this.selectedBentchMark.push(el);
          })
          this.filterBentchMark();
          this.benchMarkIsLoading = false;
        })
    });
  }
  getIcon(brand: string) {
    if (brand == 'Jack In A Box') {
      return this.assetUrl + "/assets/JackInTheBox Logo@2x.png";
    }
    if (brand == 'Sonic') {
      return this.assetUrl + "/assets/sonic-logo.png"
    }
    if (brand == 'Whataburger') {
      return this.assetUrl + "/assets/Whataburger_logo.png"
    }
    if (brand == 'Taco Bell') {
      return this.assetUrl + "/assets/taco-bell.png"
    }
    if (brand == 'In N Out') {
      return this.assetUrl + "/assets/In-N-Out-Burger.webp"
    }
    if (brand == 'Hardees') {
      return this.assetUrl + "/assets/hardees.webp"
    }
    if (brand == 'Carl\'s Jr.') {
      return this.assetUrl + "/assets/carlsjr_logo.png"
    }
    return "";
  }

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  @HostListener("window:scroll", [])
  onScrollEvent() {
    this.scrollY = window.scrollY;
  }
  restaurantCompititorsChange(event: object[]) {
    this.competitorsForModelingTool = event;
  }

  ApplyChangeRestaurantNum(event) {
    this.selectedModelingRes = event;
    this.selectedRestaurantNumber = event;
    const eles = this.modelingDateRange.filter(ele => ele.showInSelector);
    this.dateRange = eles;
    this.RefreshCompetitors(event);
  }

  showCardChange(event) {
    this.showCard = event;
  }

  ChangeServiceMode(value) {
    this.selectedServiceMode = value;
  }

  openSaveModel() {
    this.ngbmodalService.dismissAll();
    this.saveModalRef.openModal();

  }

  changeSaveModel(event) {
    this.modalPriceToSave = event;
  }
  changeCardDetail(event) {
    this.salesCardDetails = event;
  }
  openManageModel(content) {
    this.modelDeleteIds = [];
    this.showApplyMultiModel = false;
    this.modelIsApplied = false;
    this.ngbmodalService.open(content, { windowClass: 'manageModel' });
    this.isManageModelLoading = true;
    this.salesService.GetRestaurantPluPriceModelsForStore(this.selectedModelingRes).subscribe(res => {

      this.isManageModelLoading = false;
      this.savedModel = res;
      this.savedModel.forEach(model => model.oldName = model.name);
      this.editMode = new Array(this.savedModel.length).fill(false);
      this.disableMode = new Array(this.savedModel.length).fill(false);
    })
  }

  openPriceExport(content) {
    this.ngbmodalService.open(content, { windowClass: 'exportPrice' });
  }

  saveModel() {
    this.showSavedModel = true;
  }

  closeOffCanvas() {
    this.modalService.dismiss();
  }
  ChangeModelName(name, i) {
    this.savedModel[i].name = name;
  }

  changeTab() {
    this.checkedModelCompetitors = true;
    if (this.selectedCompetitors.length == 0 && this.nearMcdCompetitors.length > 0 && this.nearWendyCompetitors.length > 0 && this.nearOtherCompetitors.length > 0) {
      this.competitorsForModelingTool = this.nearestCompetitors;
    }
    this.selectedModelingRes = this.selectedRestaurantNumber;
    this.changeHeaderTab(2)
  }

  applyModelRestaurantNumsFormControls: FormControl[] = [];

  applyToMulti(item: any, content) {
    this.applyModelRestaurantNumsFormControls = [];
    this.modelIsApplied = false;
    this.applyModelName = item.name;
    if (this.restaurantGroupsForModelChanges.length == 0) {

      this.restaurantGroupsForModelChanges.push(1);
    }
    this.applyModelRestaurantNumsFormControls.push(new FormControl());
    this.showApplyMultiModel = true;
    // this.ngbmodalService.open(content, { windowClass: 'applyModel' });
  }

  AddApplyModelRestaurantRow() {
    this.restaurantGroupsForModelChanges.push(1);
    this.applyModelRestaurantNumsFormControls.push(new FormControl());
  }

  RemoveApplyModelRestaurantRow(item) {
    if (this.restaurantGroupsForModelChanges.length == 1) return;
    this.restaurantGroupsForModelChanges.pop();
    const index =   this.applyModelRestaurantNumsFormControls.indexOf(item);
    this.applyModelRestaurantNumsFormControls.splice(index, 1);
  }

  applyModelChanges() {
    this.modelIsApplied = true;
  }

  ApplyChangeRestaurantsNum(event, index: number) {
    console.log(event);
    console.log(index);
    this.filters.restaurant_num = event;
    this.filtersProxy.restaurant_num = event;
    this.RefreshCompetitors(event);
    if (this.selectedModelingNums.length == 0) {
      this.selectedModelingNums.push(event);
    } else {
      this.selectedModelingNums[index] = event;
    }
  }

  ApplyChangeRestaurantsGroup(event) {
    this.availableRestaurant = [];
    this.selectedRestaurantGroup = event;
    this.changeRestaurant.forEach((x: any) => {
      if (x.groupName == this.selectedRestaurantGroup) {
        this.availableRestaurant.push(x);
      }
    })
    
    this.availableRestaurant = this.getUniqueRestaurantSet(this.availableRestaurant);
    this.selectedModelingRes = this.availableRestaurant[0].restaurantNum;
    this.selectedRestaurantNumber = this.availableRestaurant[0].restaurantNum;
    this.RefreshCompetitors(this.availableRestaurant[0].restaurantNum);
  }

  cancelApplyModels() {
    this.applyModelRestaurantNumsFormControls = [];
    this.showApplyMultiModel = false;
    this.modelIsApplied = false;
  }

  changeCategory(event) {
    this.selectedSubGroup = event;
    this.filtersProxy.menuCategory = this.selectedSubGroup;
  }
  clearSearchText() {
    setTimeout(() => {
      this.searchText = "";
      this.groupSearchText = "";
    }, 500);
  }
  previousSelectedTab;
  changeHeaderTab(tabId) {
    this.previousSelectedTab = tabId;
    if (this.canChange == true) {
      this.nav.select(tabId)
    } else {
      this.ngbmodalService.open(this.mymodal);
    }
  }
  changeMenuTab(menuSwitch){
    if(menuSwitch=1){
      this.showMenuTab = true;
    }
    else {
      this.showMenuTab = false;
    }
  }
  changeRest: boolean = false;
  changeGroup: boolean = false;
  changeWithoutSave() {
    if (this.changeRest) {
      this.canChange = true;
      this.sharedService.canChange.emit(true);
      this.selectedRestaurantNumber = this.resNum;
      this.RefreshCompetitors(this.resNum);
      this.ngbmodalService.dismissAll();
      this.changeRest = false;
    }
    if (this.changeGroup) {
      this.changeGroup = false;
      this.canChange = true;
      this.sharedService.canChange.emit(true);
      this.selectedRestaurantGroup = this.resGrp
      this.refreshRestaurantNum(this.selectedRestaurantGroup);
      this.ngbmodalService.dismissAll();
    } else {
      this.canChange = true;
      this.sharedService.canChange.emit(true);
      this.changeHeaderTab(this.previousSelectedTab);
      this.ngbmodalService.dismissAll();
    }
  }

  exportPriceXlsx(groupName, table) {
       
    var prices = [];
    table.tableData.forEach(e => {
        if(e.overridePrice) {
            var priceRow = {
                            "Plu Number" : e.pluNum,                                                        
                            "Product Name": e.pluName,
                            "Price Group": groupName.value,
                            "Price ($)": e.overridePrice
                            };
                prices.push(priceRow);
         }
    })
    const columnNames = ["Plu Number", "Product Name", "Price Group", "Price ($)"]
    const columnFormats = ['s', 's','n','n'];
    const worksheet = XLSX.utils.json_to_sheet(prices);
    const ws = XLSX.utils.sheet_add_aoa(worksheet, [[...columnNames]]);   
    
    var csv =  XLSX.utils.sheet_to_csv(worksheet);
    //const workbook = XLSX.utils.book_new();
    //XLSX.utils.book_append_sheet(workbook, csvOutput, "Price Extract");
        //XLSX.writeFile(workbook, "POS_Export.csv");
        // Create a Blob from the CSV String
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);
        // Create a temporary anchor element and trigger download
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'pos-export.csv'; // File name for download
        document.body.appendChild(anchor); // Required for Firefox
        anchor.click();
        // Clean up
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
  
  }

  downloadXlsx() {
    if (this.activeId == 1){
      this.downloadXlsxBtn = true;
      this.menuItemPriceService.GetExcelExportDetailsToDownload().subscribe({
      next: async res => {
        const columnsToDelete = ["restaurantGroupName", 'userId', 'menuCategory', 'bkCompetitorRestaurantId', 'soldTypeId', 'restaurantGroupId', 'conceptPluId', 'bkPluId', 'distance', 'competitorRestaurantNum']
        res.forEach(element => {
          columnsToDelete.forEach(col => {
            delete (element[col])
          })
        });
        const columnNames = ["Restaurant #", "BKC Address", "SICOM PLU",   "BK Current Price", "Product Data Collection Date",  "Product Name", "Sold Type", "Delivery", "Competitor Name", "Competitor Address", "Competitor Product Name", "Competitor Price", "Competitor Data Collection Date"]
        const columnFormats = ['n', 's', 'n', 'n', 's' , 's', 's', 's', 's', 's', 's', 'n', 's']
        const columnz = ['0', null, '0', '0.00', null, null, null,  null, null, null, null, '0.00', null];
        const worksheet = XLSX.utils.json_to_sheet(res);
        const ws = XLSX.utils.sheet_add_aoa(worksheet, [[...columnNames]]);
        // format price columns as numbers 

        for(var i = 0; i < res.length; i++)
        {
            for(var j = 0; j < columnNames.length; j++){
                var cell = ws[XLSX.utils.encode_cell({r:i+1, c: j})];
                if (cell.v != null) {
                  cell.t = columnFormats[j];
                  if (columnz[j] != null) cell.z = columnz[j];
                }
    
            }
        }


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws, "Price Extract");

        XLSX.writeFile(workbook, "RMP_Price_Extract_Report.xlsx");
        this.downloadXlsxBtn = false;
      },
      error: err => {
        this.downloadXlsxBtn = false;
      }
    })
  }
  else if (this.activeId == 2){
    this.sharedService.downloadModelEvent.emit();
  }
  }

  changeSoldType() {
    this.availableSoldTypes = [...this.availableSoldTypes];
  }

  resNum: any;
  resGrp: any;
  RefreshCompetitorsModeling(restNum) {
    this.changeRest = true;
    this.resNum = restNum;
    if (this.canChange) {
      this.RefreshCompetitors(restNum);
    }
    else {
      setTimeout(() => {
        this.selectedRestaurantNumber = this.selectedModelingRes;
      }, 100);
      this.ngbmodalService.open(this.mymodal)
    }
  }

  prevRestGrp: any;
  refreshRestaurantNumModeling(groupValue) {
    this.changeGroup = true;
    this.resGrp = groupValue;
    if (this.canChange) {
      this.refreshRestaurantNum(groupValue);
    }
    else {
      setTimeout(() => {
        this.selectedRestaurantGroup = this.prevRestGrp;
      }, 100);
      this.ngbmodalService.open(this.mymodal)
    }
  }

  removeComma(num: string) {
    return num = num.replace(/\,/g, '');
  }
  confirmModelApplication(){
    this.reataurantService.copyRestaurantModelToOtherRestaurants(this.applyModelName, this.selectedModelingNums).subscribe(res => console.log(res));
  }


  getSetupPage(){
    return this.TierService.getSetupPage();
  }
  
  getUniqueRestaurantSet(restaurants){
    return (uniqueSet => restaurants.filter(restaurant => {
      const isUnique = !uniqueSet.has(restaurant.restaurantNum);
      if (isUnique) {
        uniqueSet.add(restaurant.restaurantNum);
      }
      return isUnique;
    }))(new Set<number>());

  }
}
